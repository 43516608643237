import { FC, useEffect } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useGetAvatar } from "../core/_requests";
import { useAuth } from "../../auth";

interface Props {
  styles: string;
}

export const Avatar: FC<Props> = ({ styles }) => {
  const { currentUser } = useAuth();

  const {
    mutate: getAvatar,
    data: avatar,
    isLoading: isLoadingAvatar,
  } = useGetAvatar();

  useEffect(() => {
    getAvatar();
  }, [currentUser]);

  return (
    <div className="">
      {isLoadingAvatar ? (
        <span
          className={`${styles}  spinner-border align-middle text-gray-400`}
        />
      ) : avatar ? (
        <img
          src={avatar}
          alt=""
          style={{ borderRadius: "50%" }}
          className={`${styles}  border border-2 border-gray-300`}
        />
      ) : (
        <img
          src={toAbsoluteUrl("/media/avatars/blank.png")}
          alt="Metornic"
          style={{ borderRadius: "50%" }}
          className={`${styles} border border-2 border-gray-300`}
        />
      )}
    </div>
  );
};
