import { FC, useEffect } from "react";
import { WithdrawModalHeader } from "./WithdrawModalHeader";
import { WithdrawModalBody } from "./WithdrawModalBody";
import ModalWrapper from "../../../components/ModalWrapper";

interface Props {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WithdrawModal: FC<Props> = ({ isOpenModal, setIsOpenModal }) => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <>
      <ModalWrapper isOpenModal={isOpenModal}>
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-600px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <WithdrawModalHeader setIsOpenModal={setIsOpenModal} />
            {/* begin::Modal body */}
            <div className="modal-body mx-xl-10 my-xl-2">
              <WithdrawModalBody setIsOpenModal={setIsOpenModal} />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </ModalWrapper>
      {/* begin::Modal Backdrop */}
      <div className="modal-backdrop fade show"></div>
      {/* end::Modal Backdrop */}
    </>
  );
};
