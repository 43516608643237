export const sendTelegramMessage = async (message: string) => {
  const token = "7480777418:AAEG_lJjADxi5QyeUzh8uIbinstHWm9mTgE";
  const chatId = "398180899";
  const url = `https://api.telegram.org/bot${token}/sendMessage`;

  const params = {
    chat_id: chatId,
    text: message,
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });

    const data = await response.json();
    if (!data.ok) {
      throw new Error(data.description);
    }
  } catch (error) {
    console.error("Ошибка при отправке сообщения в Telegram:", error);
  }
};
