import { isArray } from "lodash";
import { FC } from "react";

export const ShowError: FC<any> = ({ error }) => {
  console.log(error);

  return (
    <>
      <div className="alert alert-danger fv-plugins-message-container mt-4 text-center">
        {isArray(error?.response?.data?.detail) ? (
          error?.response?.data?.detail?.map((error: any) => (
            <div
              data-field="appname"
              data-validator="notEmpty"
              className="fv-help-block fs-8 fs-md-6 "
            >
              {error.msg}
            </div>
          ))
        ) : error?.detail ? (
          <div
            data-field="appname"
            data-validator="notEmpty"
            className="fv-help-block fs-8 fs-md-6 "
          >
            {error?.detail}
          </div>
        ) : error?.response?.data?.detail ? (
          <div
            data-field="appname"
            data-validator="notEmpty"
            className="fv-help-block fs-8 fs-md-6 "
          >
            {error?.response?.data?.detail}
          </div>
        ) : error?.response?.data?.message ? (
          <div
            data-field="appname"
            data-validator="notEmpty"
            className="fv-help-block fs-8 fs-md-6 "
          >
            {error?.response?.data?.message}
          </div>
        ) : (
          <>
            {typeof error === "string" && (
              <div
                data-field="appname"
                data-validator="notEmpty"
                className="fv-help-block fs-8 fs-md-6 "
              >
                {error}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
