import { useIntl } from "react-intl";

const API_URL = process.env.REACT_APP_TELEGRAM_BOT_URL;

export const TelegramForgotBtn = () => {
  const intl = useIntl();

  return (
    <div className="">
      <a
        href={API_URL}
        target="_blank"
        className="clickable-text text-gray-500"
        rel="noreferrer"
      >
        {intl.formatMessage({ id: "AUTH.NO_TELEGRAM_ACCESS" })}
      </a>
    </div>
  );
};
