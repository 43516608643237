import React, { FC } from "react";

interface Props {
  currentItemsValue: number;
  setCurrentItemsValue: (page: number) => void;
  data: any;
  itemsValue: number;
  startCurrentItemsValue: number;
}

export const Pagination: FC<Props> = ({
  currentItemsValue,
  setCurrentItemsValue,
  data,
  itemsValue,
  startCurrentItemsValue,
}) => {
  const handleClickPrePage = () => {
    if (currentItemsValue === startCurrentItemsValue) return;
    setCurrentItemsValue(currentItemsValue - itemsValue);
  };

  const handleClickNextPage = () => {
    setCurrentItemsValue(currentItemsValue + itemsValue);
  };

  const isNextPageAvailable = data?.length === itemsValue;

  const showPagination =
    currentItemsValue !== startCurrentItemsValue || isNextPageAvailable;

  return showPagination ? (
    <ul className="pagination">
      <button
        className="page-item previous btn btn-sm"
        onClick={handleClickPrePage}
        disabled={currentItemsValue === startCurrentItemsValue}
        style={{ border: "none", background: "none" }}
      >
        <span className="page-link">
          <i className="previous"></i>
        </span>
      </button>

      <button
        className="page-item next btn btn-sm"
        onClick={handleClickNextPage}
        disabled={!isNextPageAvailable}
        style={{ border: "none", background: "none" }}
      >
        <span className="page-link">
          <i className="next"></i>
        </span>
      </button>
    </ul>
  ) : null;
};
