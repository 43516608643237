import React from "react";
import { useIntl } from "react-intl";
import { Tooltip } from "react-bootstrap";
import { WalletProvider } from "./core/WalletContextProvider";
import { WalletTransactions } from "./components/WalletTransactions";

import { useWalletHolds, useWalletTransactions } from "./core/_requests";
import { Withdraw } from "./components/Withdraw";
import { WithdrawTransactions } from "./components/WithdrawTransactions";

interface Props {}

export const WalletPage: React.FC<Props> = React.memo(() => {
  const intl = useIntl();

  const {
    mutate: getTransaction,
    data: dataTransaction,
    isLoading: isWalletTransactionsLoading,
  } = useWalletTransactions();

  const {
    mutate: getHolds,
    data: dataHolds,
    isLoading: isWalletHoldsLoading,
  } = useWalletHolds();

  const tooltip = (
    <Tooltip id="tooltip">
      <h4 className="text-start">
        {intl.formatMessage({ id: "WALLET.ASSETS_FROZEN__TOOLTIP_TITLE" })}
      </h4>
      <div className="separator separator-dashed mb-2"></div>
      <div className="text-start">
        {intl.formatMessage({ id: "WALLET.ASSETS_FROZEN_TOOLTIP_PART1" })}
      </div>
      <div className="text-start">
        {intl.formatMessage({ id: "WALLET.ASSETS_FROZEN_TOOLTIP_PART2" })}
      </div>
    </Tooltip>
  );

  return (
    <WalletProvider>
      <div className="">
        <div className="">
          <Withdraw />
          <div className="row gy-10 gx-xl-10">
            <div className="col-12">
              <WalletTransactions
                className=""
                getTransaction={getTransaction}
                dataTransaction={dataTransaction}
                isWalletTransactionsLoading={isWalletTransactionsLoading}
                title={intl.formatMessage({
                  id: "WALLET.TRANSACTION_HISTORY_TITLE",
                })}
                subTitle={intl.formatMessage({
                  id: "WALLET.TRANSACTION_ALL_TITLE",
                })}
                showColorsAndSigns={true}
              />
            </div>

            {dataHolds?.length !== 0 && (
              <div className="col-12">
                <WalletTransactions
                  className=""
                  getTransaction={getHolds}
                  dataTransaction={dataHolds}
                  isWalletTransactionsLoading={isWalletHoldsLoading}
                  title={intl.formatMessage({
                    id: "WALLET.ASSETS_FROZEN_TITLE",
                  })}
                  subTitle={intl.formatMessage({
                    id: "WALLET.ASSETS_FROZEN_SUB_TITLE",
                  })}
                  tooltip={tooltip}
                  showColorsAndSigns={false}
                />
              </div>
            )}

            <div className="col-12">
              <WithdrawTransactions className="" />
            </div>
          </div>
        </div>
      </div>
    </WalletProvider>
  );
});
