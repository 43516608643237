import { FC } from "react";
import { Toast } from "react-bootstrap";
import { useIntl } from "react-intl";

interface Props {
  showToast: boolean;
  setShowToast: (state: boolean) => void;
  state: string;
}

export const ToastTopLeft: FC<Props> = ({ showToast, setShowToast, state }) => {
  const intl = useIntl();

  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      style={{
        position: "fixed",
        minHeight: "100px",
        top: "2%",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 1050,
      }}
      className={`w-250px ${!showToast && "d-none"}`}
    >
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide={true}
        bg={state}
      >
        <div className="d-flex flex-center p-3 pt-5">
          {/* <KTIcon iconName="check" className="fs-1 text-white me-2 mb-2" /> */}
          <i className=" fs-1 text-white me-2 mb-2 ki-duotone ki- check"></i>
          <h5 className="text-white ">
            {state === "success"
              ? intl.formatMessage({ id: "GLOBAL.SUCCESS" })
              : intl.formatMessage({ id: "GLOBAL.ERROR" })}
          </h5>
        </div>
      </Toast>
    </div>
  );
};
