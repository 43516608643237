import { FC } from "react";
import { useIntl } from "react-intl";

interface BadgeStatusProps {
  status: string;
}

export const BadgeStatusDeal: FC<BadgeStatusProps> = ({ status }) => {
  const intl = useIntl();

  const getBadgeClass = (state: string) => {
    switch (state) {
      case "new":
        return "badge-light-primary";
      case "canceled":
        return "badge-light-danger";
      case "running":
        return "badge-light-warning";
      case "completed":
        return "badge-light-success";
      default:
        return "";
    }
  };

  const getBadgeText = (state: string) => {
    switch (state) {
      case "new":
        return intl.formatMessage({ id: "DEAL.STATE.NEW" });
      case "canceled":
        return intl.formatMessage({ id: "DEAL.STATE.CANCELED" });
      case "running":
        return intl.formatMessage({ id: "DEAL.STATE.RUNNING" });
      case "completed":
        return intl.formatMessage({ id: "DEAL.STATE.COMPLETED" });
      default:
        return "";
    }
  };

  return (
    <span className={`badge py-3 px-4 fs-8 fs-sm-7 ${getBadgeClass(status)}`}>
      {getBadgeText(status)}
    </span>
  );
};
