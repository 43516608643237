import { FC, useRef, useState } from "react";
import { TelegramLoginButton } from "../TelegramLoginButton";
import { ShowError } from "../../../errors/components/ShowError";
import "../style.css";
import { TelegramButton } from "../../../../pages/main-page/TelegramButton";
import { useAuth } from "../../core/Auth";
import { UserTelegramWidgetData } from "../../core/_models";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { TelegramForgotBtn } from "../TelegramForgotBtn";

interface UserCode {
  userCode: string | null;
  error: any;
  isloading: boolean;
}

export const UserLoginModalBody: FC<UserCode> = ({
  userCode,
  error,
  isloading,
}) => {
  const intl = useIntl();

  return (
    <>
      {userCode === null ? (
        <>
          <div className="fs-6 mb-10">
            <p>
              {intl.formatMessage({ id: "AUTH.BOT_DIALOG" })}{" "}
              <b>{intl.formatMessage({ id: "AUTH.BOT_NAME" })}</b>
            </p>

            <p>
              {intl.formatMessage({ id: "AUTH.BOT_START" })}{" "}
              <b>{intl.formatMessage({ id: "AUTH.START_COMMAND" })}</b>
            </p>
          </div>

          <div className={`d-block`}>
            <div className="mb-7 ">
              <TelegramLoginButton />
            </div>
            <TelegramForgotBtn />
          </div>
        </>
      ) : error ? (
        <>
          <div className="fs-6 mb-10">
            <div className="mb-13 mw-400px mx-auto text-center">
              {error && ShowError(error)}
            </div>
          </div>

          <div className="d-flex flex-center">
            <TelegramLoginButton />
          </div>
        </>
      ) : isloading ? (
        <>
          <div className="splashScreen">
            <div className="lightLogo">
              <div className="ldsDualRing"></div>
            </div>

            <span style={{ color: "rgb(0, 0, 0)" }}>
              {intl.formatMessage({ id: "AUTH.UPDATING_DATA" })}
            </span>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
