import type { FC } from "react";
import React from "react";
import { useIntl } from "react-intl";

interface Props {}

export const LoginHeader: FC<Props> = React.memo(() => {
  const intl = useIntl();

  return (
    <>
      <div className="mb-13 text-center">
        <h1 className="text-dark fw-semibold mb-3 display-6">
          {intl.formatMessage({ id: "AUTH.ENTER_CODE_PASSWORD" })}
        </h1>
      </div>
    </>
  );
});
