import React, { useEffect } from "react";
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { CrypromusCrncInfo } from "../../core/_models";
import { QRPage } from "./QRPage";

interface Props {
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const QrWalletModalBody: FC<Props> = ({ setIsOpenModal }) => {
  const intl = useIntl();

  return (
    <>
      <div className="d-flex flex-wrap justify-content-between h-100">
        <QRPage />

        <div className="w-100 d-flex align-items-end justify-content-between">
          <div className="me-2">
            <button
              type="button"
              className="btn btn-lg btn-light-primary me-3  d-flex flex-center"
              data-kt-stepper-action="previous"
              onClick={() => setIsOpenModal(false)}
            >
              <i className="ki-duotone ki-arrow-left fs-3 me-1">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
              {intl.formatMessage({ id: "BUTTON.BACK" })}
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-lg btn-primary"
              data-kt-stepper-action="next"
              onClick={() => setIsOpenModal(false)}
            >
              {intl.formatMessage({ id: "BUTTON.DONE" })}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
