import { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  convertCentsToDollars,
  convertDollarsToCents,
} from "../../../../utils";
import { useGetBalance } from "../../../accounts/core/_requests";
import { ShowError } from "../../../errors/components/ShowError";
import { useCreateWithdrawRequest } from "../../core/_requests";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";

interface Props {
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialValues = {
  amount: undefined,
  requisites: undefined,
  comment: null,
};

export const WithdrawModalBody: FC<Props> = ({ setIsOpenModal }) => {
  const intl = useIntl();

  const [page, setPage] = useState(1);

  const {
    mutate: withdrawRequest,
    isLoading: iswithdrawRequestLoading,
    error: withdrawRequestError,
  } = useCreateWithdrawRequest();

  const [error, setError] = useState<any | null>(null);

  const { data: balance, isFetching: isLoadingBalance } = useGetBalance();

  const walletData = Number(balance?.total - balance?.hold);

  const accessibleWalletData = convertCentsToDollars(walletData - 300);

  const isCommissionCheck = walletData > 300;

  const formikSchema = Yup.object().shape({
    amount: Yup.number()
      .min(2, intl.formatMessage({ id: "WITHDRAW.MIN_AMOUNT" }))
      .max(
        accessibleWalletData,
        intl.formatMessage({ id: "WITHDRAW.INSUFFICIENT_FUNDS" })
      )
      .typeError(intl.formatMessage({ id: "AUTH.VALIDATION.ENTER_INTEGER" }))
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_PRICE" }))
      .test(
        "is-decimal",
        intl.formatMessage({ id: "AUTH.VALIDATION.ENTER_INTEGER" }),
        (value) =>
          value !== undefined &&
          value !== null &&
          /^\d+$/.test(value.toString())
      ),

    requisites: Yup.string()
      .trim()
      .matches(
        /^T[a-zA-Z0-9]{33}$/,
        intl.formatMessage({ id: "WITHDRAW.INVALID_ADDRESS" })
      )
      .min(3, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH_3" }))
      .max(50, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH_50" }))
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formikSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setError(null);
      setSubmitting(true);
      try {
        withdrawRequest(
          {
            ...values,
            amount: convertDollarsToCents(values?.amount) + 300,
            comment: "Application for withdrawal of funds",
          } as any,
          {
            onSuccess: () => {
              setPage(page + 1);
            },
          }
        );
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (withdrawRequestError) {
      setError(withdrawRequestError);
    }
  }, [withdrawRequestError]);

  return (
    <div className="">
      {page === 1 && (
        <form
          className="form w-100 px-2"
          noValidate
          id="kt_login_password_reset_form"
          onSubmit={formik.handleSubmit}
        >
          <div className="my-auto w-100">
            <label className="d-flex align-items-center fs-5 fw-semibold mb-3 ms-2">
              <span className="">
                {intl.formatMessage({ id: "GLOBAL.ENTER_AMOUNT" })}
              </span>
            </label>
            <div
              className="mb-2"
              style={{ position: "relative", width: "100%" }}
            >
              <input
                type="tel"
                {...formik.getFieldProps("amount")}
                style={{ width: "100%" }}
                name="amount"
                placeholder="0.00 $"
                className={clsx(
                  "form-control fs-8 fs-md-6  form-control-solid mb-3 mb-lg-0",
                  {
                    "is-invalid": formik.touched.amount && formik.errors.amount,
                  },
                  {
                    "is-valid": formik.touched.amount && !formik.errors.amount,
                  }
                )}
                autoComplete="off"
                disabled={formik.isSubmitting}
              />
              {formik.touched.amount && formik.errors.amount && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block fs-8 fs-md-6">
                    <span role="alert">{formik.errors.amount}</span>
                  </div>
                </div>
              )}
            </div>

            <div>
              <i
                className="fas fa-exclamation-circle mt-3 fs-7 ms-1"
                data-bs-toggle="tooltip"
                title={intl.formatMessage({ id: "TOOLTIP.APP_NAME" })}
              ></i>{" "}
              <span className="text-muted w-25">
                <strong>{intl.formatMessage({ id: "WITHDRAW.FEE" })} </strong>
                {isCommissionCheck && (
                  <div className="fw-bold">
                    {intl.formatMessage({ id: "WITHDRAW.AVAILABLE" })}:{" "}
                    {accessibleWalletData} {" USDT"}
                  </div>
                )}
              </span>
            </div>

            <div>
              <label className="d-flex align-items-center fs-5 fw-semibold mt-9 mb-3">
                <span className="">
                  {intl.formatMessage({ id: "WITHDRAW.ENTER_ADDRESS" })}
                </span>
              </label>
              <input
                type="text"
                {...formik.getFieldProps("requisites")}
                className={clsx(
                  "form-control fs-8 fs-md-6  form-control-solid mb-3 mb-lg-0",
                  {
                    "is-invalid":
                      formik.touched.requisites && formik.errors.requisites,
                  },
                  {
                    "is-valid":
                      formik.touched.requisites && !formik.errors.requisites,
                  }
                )}
                name="requisites"
                placeholder="TAiJczuW7sJUTRuXnpRS4cV3hDxzP79bW2"
              />
              {formik.touched.requisites && formik.errors.requisites && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block fs-8 fs-md-6">
                    <span role="alert">{formik.errors.requisites}</span>
                  </div>
                </div>
              )}
            </div>

            <div>
              <i
                className="fas fa-exclamation-circle mt-3 fs-7 ms-1"
                data-bs-toggle="tooltip"
                title={intl.formatMessage({ id: "TOOLTIP.APP_NAME_REQ" })}
              ></i>{" "}
              <span className="text-muted w-25">
                {intl.formatMessage({ id: "WITHDRAW.USDT_WALLET_REQUIRED" })}
              </span>
            </div>

            <div>
              <div className="text-muted w-100 text-end mt-5">
                <span className="fw-bold fs-6">
                  {intl.formatMessage({ id: "WITHDRAW.TOTAL" })}:{" "}
                  {Number(formik?.values?.amount ?? 0) + 3} {" USDT"}
                </span>
              </div>
            </div>

            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {error && <ShowError error={error} />}

          {/* end::Scroll */}

          {/* begin::Actions */}
          <div className="d-flex align-items-center justify-content-between mt-9 mb-2 ">
            <button
              type="reset"
              onClick={() => setIsOpenModal(false)}
              className="d-block  btn btn-light  px-6"
            >
              {intl.formatMessage({ id: "GLOBAL.BACK" })}
            </button>

            <button
              type="submit"
              disabled={iswithdrawRequestLoading || isLoadingBalance}
              className="d-block ms-auto btn btn-primary  px-6"
            >
              {(iswithdrawRequestLoading || isLoadingBalance) && (
                <span
                  className="spinner-border spinner-border-sm me-4"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {intl.formatMessage({ id: "PROFILE.WITHDRAW_ACTION" })}
            </button>
          </div>
        </form>
      )}
      {page === 2 && (
        <div className="px-3">
          <h1>{intl.formatMessage({ id: "WITHDRAW.REQUEST_TITLE" })}</h1>
          <p>{intl.formatMessage({ id: "WITHDRAW.REQUEST_DESCRIPTION" })}</p>
          <p>
            {intl.formatMessage({ id: "WITHDRAW.AMOUNT_TO_CREDIT" })}{" "}
            <strong>{formik.values.amount}</strong>
          </p>
          <p>
            {intl.formatMessage({ id: "WITHDRAW.USDT_WALLET" })}{" "}
            <strong>{formik.values.requisites}</strong>
          </p>
          <p>{intl.formatMessage({ id: "WITHDRAW.VIEW_REQUESTS" })}</p>

          <div className="w-100 d-flex align-items-center justify-content-between mt-10">
            <button
              onClick={() => {
                setIsOpenModal(false);
                window.location.reload();
              }}
              className="w-100 d-block  btn btn-primary  px-6 me-3"
            >
              {intl.formatMessage({ id: "WITHDRAW.RETURN_TO_WALLET" })}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
