import axios from "axios";
import { useQuery, useMutation } from "react-query";
import { OtherUser } from "./_models";

const AVATAR = `/api/profile/avatar/`;

const BALANCE = `/api/balance/`;

const USERS = `/api/users/`;

export const useGetAvatar = () => {
  return useMutation("avatar", async (uuid) => {
    const { data } = await axios.get(AVATAR);
    return data.avatar;
  });
};

export const useGetBalance = (options: any = {}) => {
  return useQuery(
    "get-balance",
    async () => {
      const { data } = await axios.get(BALANCE);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useGetOtherUserData = () => {
  return useMutation<OtherUser, any, any>("other-user", async (uuid) => {
    const { data } = await axios.get(`${USERS}${uuid}/`);
    return data;
  });
};

export const useGetOtherUserAvatar = () => {
  return useMutation<any, any, any>("other-user-avatar", async (uuid) => {
    const { data } = await axios.get(`${USERS}${uuid}/avatar/`);
    return data.avatar;
  });
};
