import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { useAuth } from "../../../auth";
import { KTIcon } from "../../../../../_metronic/helpers";
import { ModalLayout } from "../../../../../_metronic/partials/modals/ModalLayout";

interface Props {
  styles?: string;
}

export const BlockBtn: FC<Props> = ({ styles }) => {
  const intl = useIntl();

  const { sessionLock } = useAuth();

  const [modalData, setModalData] = useState<any | null>(null);

  const setModalBlock = () => {
    setModalData({
      title: intl.formatMessage({ id: "MODAL.BLOCK_SESSION.TITLE" }),
      desc: intl.formatMessage({ id: "MODAL.BLOCK_SESSION.DESC" }),
      action: () => sessionLock(),
      btnTrueName: intl.formatMessage({ id: "GENERAL.YES" }),
      btnFalseName: intl.formatMessage({ id: "GENERAL.NO" }),
    });
  };

  return (
    <>
      <button className="btn btn-sm btn-secondary" onClick={setModalBlock}>
        <KTIcon iconName="lock" className="fs-4 mb-1 ms-1" />
        <span className={styles}>
          {intl.formatMessage({ id: "BUTTON.BLOCK" })}
        </span>
      </button>

      <ModalLayout
        title={modalData?.title}
        show={Boolean(modalData)}
        handleClose={() => setModalData(null)}
      >
        {modalData?.desc && (
          <div className="mb-10 fs-md-4 fs-7 fw-bold text-center">
            {modalData?.desc}
          </div>
        )}

        <div className="p-2 d-flex justify-content-center align-items-center">
          <button
            type="button"
            disabled={modalData?.isLoading}
            className="d-block me-5 me-sm-20 btn btn-success btn-sm px-10 px-sm-15"
            onClick={() => {
              modalData?.action();
              setModalData(null);
            }}
          >
            {modalData?.isLoading && (
              <span
                className="spinner-border spinner-border-sm me-4"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {modalData?.btnTrueName}
          </button>

          <button
            type="button"
            className="d-block btn btn-danger btn-sm px-8 px-sm-15"
            onClick={() => {
              setModalData(null);
            }}
          >
            {modalData?.btnFalseName}
          </button>
        </div>
      </ModalLayout>
    </>
  );
};
