import { FC, createContext, useContext } from "react";
import { WithChildren } from "../../../helpers";
import { Menu } from "./Menu";

// Создаем контекст
const MenuContext = createContext<any>({});

// Компонент провайдера контекста
export const MenuProvider: FC<WithChildren> = ({ children }) => {
  return (
    <MenuContext.Provider value={{}}>
      {children}

      <div className=" d-lg-none">
        <Menu />
      </div>
    </MenuContext.Provider>
  );
};

export const useMenu = () => useContext(MenuContext);
