import { FC } from "react";
import { TelegramLoginButton } from "../TelegramLoginButton";
import "../style.css";
import { useIntl } from "react-intl";

export const UserLockModalBody: FC = () => {
  const intl = useIntl();

  return (
    <>
      <div className="fs-6 mb-10">
        <p>
          {intl.formatMessage({ id: "AUTH.CONTACT_SUPPORT" })}
          <b> {intl.formatMessage({ id: "AUTH.SUPPORT_BOT" })}</b>
        </p>
      </div>

      <div className="d-flex flex-center">
        <TelegramLoginButton />
      </div>
    </>
  );
};
