import type { FC } from "react";
import React from "react";
import { useIntl } from "react-intl";

interface Props {}

export const RegistrationHeader: FC<Props> = React.memo(() => {
  const intl = useIntl();

  return (
    <>
      <div className="mb-10">
        <h1 className="text-dark fw-semibold mb-3 w-lg-400px display-6 mb-5">
          {intl.formatMessage({ id: "AUTH.REGISTER" })}
        </h1>

        <p className="text-gray-500 pt-1 fw-semibold fs-6">
          {intl.formatMessage({ id: "AUTH.SAFE_DEALS_SERVICE" })}
        </p>
      </div>
    </>
  );
});
