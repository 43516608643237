import axios from "axios";
import { useMutation, useQuery } from "react-query";
import {
  CrypromusCrncInfo,
  CurrencyUserData,
  CurrencyUserInfo,
  TransactionHistory,
  WithdrawHistory,
} from "./_models";

const WALLET = `/api/balance/wallet/`;

const HISTORY_TRANSACTION = `/api/balance/history/`;

const HISTORY_HOLDS = `/api/balance/holds/`;

const WITHDRAWAL = `/api/withdraw/`;

const CRYPTOMUS_URL = process.env.REACT_APP_CRYPTOMUS as string;

export const useGetCrypromusCrnc = (options: any = {}) => {
  return useQuery<CrypromusCrncInfo[]>(
    "crypromus-crnc",
    async () => {
      const { data } = await axios.get(CRYPTOMUS_URL, {
        headers: {
          "x-api-key": "123",
        },
      });
      return data.valute_list;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useCreateWallet = () => {
  return useMutation<CurrencyUserData, any, CurrencyUserInfo>(
    "exchange-rate",
    async (currencyUserInfo: CurrencyUserInfo) => {
      const { data: response } = await axios.post(
        CRYPTOMUS_URL,
        currencyUserInfo,
        {
          headers: {
            "x-api-key": "123",
          },
        }
      );
      return response;
    }
  );
};

export const useGetExchange = () => {
  return useMutation<any, any, string>(
    "exchange-rate",
    async (exchange: string) => {
      const { data: response } = await axios.get(
        `${CRYPTOMUS_URL}exchange/${exchange}/`,
        {
          headers: {
            "x-api-key": "123",
          },
        }
      );
      return response.course;
    }
  );
};

export const useGetWallet = (options: any = {}) => {
  return useQuery(
    "get-wallet",
    async () => {
      const { data } = await axios.get(WALLET);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useWalletTransactions = () => {
  return useMutation<TransactionHistory[], any, any>(
    "history-transaction",
    async (reqData) => {
      const { data } = await axios.get(
        `${HISTORY_TRANSACTION}?limit=${reqData.itemsValue}&offset=${reqData.currentItemsValue}`
      );
      return data.history;
    }
  );
};

export const useWalletHolds = () => {
  return useMutation<TransactionHistory[], any, any>(
    "history-holds",
    async (reqData) => {
      const { data } = await axios.get(
        `${HISTORY_HOLDS}?limit=${reqData.itemsValue}&offset=${reqData.currentItemsValue}`
      );
      return data.holds;
    }
  );
};

export const useCreateWithdrawRequest = () => {
  return useMutation<CurrencyUserData, any, CurrencyUserInfo>(
    "withdraw-request",
    async (request: CurrencyUserInfo) => {
      const { data: response } = await axios.post(
        `${WITHDRAWAL}request/`,
        request
      );
      return response;
    }
  );
};

export const useWithdrawTransactions = () => {
  return useMutation<WithdrawHistory[], any, any>(
    "history-withdraw",
    async (reqData) => {
      const { data } = await axios.get(
        `${WITHDRAWAL}request/me/?limit=${reqData.itemsValue}&offset=${reqData.currentItemsValue}`
      );
      return data.requests;
    }
  );
};

export const useCancelWithdraw = () => {
  return useMutation<WithdrawHistory[], any, string>(
    "cancel-withdraw",
    async (uuid) => {
      const { data } = await axios.post(`${WITHDRAWAL}${uuid}/cancel/`);
      return data.requests;
    }
  );
};
