import { FC, useEffect } from "react";
import { UserLoginModalHeader } from "./UserLoginModalHeader";
import { UserLoginModalBody } from "./UserLoginModalBody";

interface UserCode {
  userCode: string | null;
  error: any;
  isloading: boolean;
}

export const UserLoginModal: FC<UserCode> = ({
  userCode,
  error,
  isloading,
}) => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-600px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <UserLoginModalHeader />
            {/* begin::Modal body */}
            <div className="modal-body  mx-5 mx-xl-10 my-2">
              <UserLoginModalBody
                userCode={userCode}
                error={error}
                isloading={isloading}
              />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className="modal-backdrop fade show"></div>
      {/* end::Modal Backdrop */}
    </>
  );
};
