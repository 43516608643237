import axios from "axios";
import { useQuery } from "react-query";
import { FaqData } from "../../../api/core-contract";

const FAQ = `/api/faq/`;

export const useGetFaq = (options: any = {}) => {
  return useQuery<FaqData>(
    "faq",
    async () => {
      const { data } = await axios.get(FAQ);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};
