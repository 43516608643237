import { FC } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { useIntl } from "react-intl";

interface Props {
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WithdrawModalHeader: FC<Props> = ({ setIsOpenModal }) => {
  const intl = useIntl();

  return (
    <div className="modal-header px-10 px-md-15 py-5 py-md-8">
      {/* begin::Modal title */}
      <h2 className="fw-bolder mb-0">
        {intl.formatMessage({ id: "WALLET.WITHDRAW.TITLE" })}
      </h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      {/* <div
        className="btn btn-icon btn-sm btn-primary btn-active-icon-light"
        data-kt-users-modal-action="close"
        onClick={() => {
          setIsOpenModal(false);
        }}
        style={{ cursor: "pointer" }}
      >
        <KTIcon iconName="cross" className="fs-1" />
      </div> */}
      {/* end::Close */}
    </div>
  );
};
