/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { LoginHeader } from "./LoginHeader";
import { Link, useNavigate } from "react-router-dom";
import { ShowError } from "../../errors/components/ShowError";
import "./style.css";
import { UserLoginModal } from "./user-login-modal/UserLoginModal";
import { useAuthUnlockPin, useSetAuthCode } from "../core/_requests";
import { useAuth } from "../core/Auth";
import { UserLockModal } from "./user-lock-modal/UserLockModal";
import { useIntl } from "react-intl";
import { getAttemptWord } from "../../../utils";
import { sendTelegramMessage } from "./sendTelegramMessage";

interface CustomError extends Error {
  response?: {
    data: any;
    status: number;
    headers: any;
  };
}

const initialValuesMask = ["_", "_", "_", "_", "_", "_"];
const regex = /^[0-9]+$/;

export function Login() {
  const intl = useIntl();

  const [inputValue, setInputValue] = useState("");
  const [inputMaskValues, setInputMaskValues] = useState(initialValuesMask);

  const [indexPass, setIndexPass] = useState<number>(0);
  const [updScore, setUpdScore] = useState<number>(0);
  const [previousValue, setPreviousValue] = useState("");
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const [checkScore, setCheckScore] = useState<number>(0);
  const [inputMaskBg, setInputMaskBg] = useState<boolean | null>(null);
  const [error, setError] = useState<any | null>(null);

  const [isPinHave, setIsPinHave] = useState<boolean | undefined>(undefined);
  const [errorUserCode, setErrorUserCode] = useState<any | null>(null);

  const [isInputPinBlocked, setIsInputPinBlocked] = useState(false);

  const navigate = useNavigate();

  const {
    isAuth,
    setIsAddPinCode,
    isLockUser,
    setIsAuth,
    userCode,
    hasErrorsForgotPin,
  } = useAuth();

  const {
    mutate: setAuthCode,
    isLoading: isSetAuthCodeLoading,
    data: authCodeData,
    error: setAuthCodeError,
  } = useSetAuthCode();

  const {
    mutate: setAuthUnlockPin,
    data: authUnlockPinData,
    error: authUnlockPinError,
  } = useAuthUnlockPin();

  useEffect(() => {
    if (setAuthCodeError || authUnlockPinError instanceof Error) {
      const customError =
        (setAuthCodeError as CustomError) ||
        (authUnlockPinError as CustomError);

      if (
        customError.response &&
        customError.response.status >= 500 &&
        customError.response.status < 600
      ) {
        sendTelegramMessage("У нас ошибка 5XX: " + customError.response.status);
      }

      setErrorUserCode({ error: customError });
    }
  }, [setAuthCodeError, authUnlockPinError]);

  useEffect(() => {
    if (authUnlockPinData) {
      setInputMaskBg(true);
      setIsAddPinCode(true);
    }
  }, [authUnlockPinData]);

  useEffect(() => {
    if (userCode) {
      setAuthCode(userCode);
    }
  }, [userCode]);

  useEffect(() => {
    if (authCodeData) {
      authCodeData?.profile
        ? setIsPinHave(authCodeData?.profile)
        : goRegistration();
    }
  }, [authCodeData]);

  useEffect(() => {
    if (setAuthCodeError instanceof Error) {
      const customError = setAuthCodeError as CustomError;

      setErrorUserCode({ error: customError });
    }
  }, [setAuthCodeError]);

  function goRegistration() {
    setIsAuth(false);
    navigate("/auth/registration");
  }

  const handlePassChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (!regex.test(value) && value !== "") return;

    if (value.length > 6) {
      return;
    } else {
      if (error || inputMaskBg !== null) {
        setError(null);
        setInputMaskBg(null);
      }

      const splitValue: string[] = value.split("");
      const newValues: string[] = [];

      setInputValue(value);

      for (let i = 0; i < 6; i++) {
        if (
          splitValue[i] &&
          i === splitValue.length - 1 &&
          value.length > previousValue.length
        ) {
          newValues.push(splitValue[i]);
        } else if (splitValue[i]) {
          newValues.push("*");
        } else {
          newValues.push("_");
        }
      }

      setInputMaskValues(newValues);

      if (timer) {
        clearTimeout(timer);
      }

      const newTimer = setTimeout(() => {
        if (value.length > previousValue.length) {
          setUpdScore(updScore + 1);
        }
      }, 1000);

      setIndexPass(splitValue.length - 1);
      setPreviousValue(value);
      setTimer(newTimer);
    }
  };

  useEffect(() => {
    if (indexPass > 0) {
      updInputMask();

      if (indexPass === 5) {
        setAuthUnlockPin(inputValue);
      }
    }
  }, [updScore]);

  const updInputMask = () => {
    const newValues = inputMaskValues.map((el, idx) => {
      if (idx === indexPass) {
        return (el = "*");
      }
      return el;
    });

    setInputMaskValues(newValues);
  };

  const handlerInvalidPassword = () => {
    setCheckScore(checkScore + 1);
    setInputMaskBg(false);
    setInputValue("");
    setInputMaskValues(initialValuesMask);
  };

  useEffect(() => {
    if (authUnlockPinError) {
      const inputMessage = authUnlockPinError?.response?.data?.message;
      const inputDetail = authUnlockPinError?.response?.data?.detail;

      const regexAttempts = /Осталось попыток (\d+)/;
      const regexSecond = /(\d+) seconds/;

      const matchAttempts = inputMessage?.match(regexAttempts);
      const matchSecond = inputDetail?.match(regexSecond);

      if (matchAttempts) {
        const attemptsLeft = parseInt(matchAttempts[1], 10);
        const errorMessage =
          intl.formatMessage({
            id: "AUTH.ERROR.PIN_INCORRECT_ATTEMPTS_LEFT",
          }) + getAttemptWord(intl, attemptsLeft);
        setError({ error: errorMessage });
        handlerInvalidPassword();
      } else if (matchSecond) {
        const seconds = parseInt(matchSecond[1], 10);
        const minutes =
          Math.floor(seconds / 60) === 0 ? 1 : Math.floor(seconds / 60);
        const errorMessage = intl.formatMessage(
          { id: "AUTH.ERROR.PIN_BLOCKED_SECONDS" },
          { minutes }
        );
        setError({ error: errorMessage });
        handlerInvalidPassword();
      } else {
        setError({ error: authUnlockPinError?.response });
        handlerInvalidPassword();
      }
    }
  }, [authUnlockPinError]);

  return (
    <div>
      <LoginHeader />

      {hasErrorsForgotPin === false && (
        <div className="mb-10 p-8 bg-light-info rounded col-lg-9 w-100 d-flex flex-center">
          <div className="text-info text-center">
            {intl.formatMessage({ id: "AUTH.RESET.EMAIL_SUCCESS" })}
          </div>
        </div>
      )}
      <div
        className={`${
          inputMaskBg === false ? "shake" : ""
        } d-flex flex-wrap flex-stack justify-content-center algin-items-center mb-15`}
        style={{
          position: "relative",
        }}
      >
        {inputMaskValues.map((value, index) => (
          <div
            key={index}
            className={`btn display-3 btn-outline btn-outline-${
              inputMaskBg === null ? null : inputMaskBg ? "success" : "danger"
            } px-5 pt-4 pb-2 w-45px`}
            style={{ cursor: "pointer", marginRight: "6px" }}
          >
            <span className="">{value}</span>
          </div>
        ))}

        <input
          type="number"
          value={inputValue}
          style={{
            opacity: "0",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
          }}
          className="form-control form-control-lg form-control-solid"
          placeholder="XXX-XXX"
          autoComplete="off"
          disabled={isInputPinBlocked}
          onChange={(e) => {
            handlePassChange(e);
          }}
        />
      </div>

      <div className="mb-10 mw-500px mx-auto text-center">
        {error && ShowError(error)}
      </div>

      <div className="d-flex fs-base fw-semibold mb-8 w-100 justify-content-end">
        {checkScore < 4 ? (
          <Link to="/auth/forgot-password" className="link-primary">
            {intl.formatMessage({ id: "AUTH.FORGOT_PASSWORD" })}
          </Link>
        ) : (
          <Link to="/auth/forgot-password" className="link-primary">
            {intl.formatMessage({ id: "AUTH.RESTORE_ACCESS" })}
          </Link>
        )}
      </div>

      {isPinHave !== true && !isAuth && !isLockUser && (
        <UserLoginModal
          userCode={userCode}
          error={errorUserCode}
          isloading={isSetAuthCodeLoading}
        />
      )}

      {isLockUser && <UserLockModal />}
    </div>
  );
}
