import React, { ReactNode, createContext, useContext, useState } from "react";
import { useGetCrypromusCrnc } from "./_requests";
import { CrypromusCrncInfo } from "./_models";

interface WalletContextType {
  currencies?: CrypromusCrncInfo[];
}

interface WalletProviderProps {
  children: ReactNode;
}

const WalletContext = createContext<WalletContextType>({} as WalletContextType);

export const WalletProvider: React.FC<WalletProviderProps> = ({ children }) => {
  const { data: currencies, isFetching: isCurrenciesLoading } =
    useGetCrypromusCrnc();

  return (
    <WalletContext.Provider value={{ currencies }}>
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);
