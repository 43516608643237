import { useIntl } from "react-intl";
import { AdsFeed } from "./AdsFeed";

export const AdsPage = () => {
  const intl = useIntl();

  return (
    <div className="w-100 d-flex flex-center mt-10">
      <div className="w-75">
        <h4 className="text-center card card-body p-10">
          {intl.formatMessage({ id: "AD_PLACEHOLDER" })}
        </h4>
      </div>
    </div>
  );
  // <AdsFeed />;
};
