import React, { useState, useEffect } from "react";

interface ModalWrapperProps {
  isOpenModal: boolean;
  children: React.ReactNode;
}

const ModalWrapper: React.FC<ModalWrapperProps> = ({
  isOpenModal,
  children,
}) => {
  const [showClass, setShowClass] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isOpenModal) {
      timer = setTimeout(() => {
        setShowClass(true);
      }, 0);
    } else {
      setShowClass(false);
    }

    return () => clearTimeout(timer);
  }, [isOpenModal]);

  return (
    <>
      <div
        className={`modal ${
          isOpenModal && showClass ? "show" : ""
        } fade d-block`}
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        {children}
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default ModalWrapper;
