import { FC, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import { Tooltip } from "bootstrap";
import { ready, crypto_sign_seed_keypair, to_hex } from "libsodium-wrappers";
import { generateMnemonic, mnemonicToSeed } from "bip39";
import { useSetPublicKey } from "../../core/_requests";
import { ShowError } from "../../../errors/components/ShowError";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { useIntl } from "react-intl";

const initialInputWords = ["", "", "", ""];
const initialCheckScore = 1;

export const SeedMnemonicPages: FC = () => {
  const intl = useIntl();

  const navigate = useNavigate();
  const [mnemonicPage, setMnemonicPage] = useState(1);

  const [mnemonic, setMnemonic] = useState<string>("");
  const [mnemonicArray, setMnemonicArray] = useState<Array<string>>([]);

  const [randomNumbers, setRandomNumbers] = useState<Array<number>>([]);
  const [inputWords, setInputWords] = useState(initialInputWords);
  const [checkScore, setCheckScore] = useState<number>(initialCheckScore);

  const [inputMaskBg, setInputMaskBg] = useState<boolean | null>(null);
  const [isTwoKeyLoading, setIsTwoKeyLoading] = useState(false);
  const [error, setError] = useState<any | null>(null);

  const [tooltip, setTooltip] = useState<any>();
  const [inProp, setInProp] = useState(false);
  const nodeRef = useRef(null);
  const mnemRef = useRef<HTMLButtonElement | null>(null);

  const {
    mutate: setPublicKey,
    data: publicKeyData,
    error: setPublicKeyError,
  } = useSetPublicKey();

  const generateRandomNumbers = () => {
    const allNumbers = Array.from({ length: 12 }, (_, i) => i + 1);
    const randomNumbers = [];

    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * allNumbers.length);
      const selectedNumber = allNumbers.splice(randomIndex, 1)[0];
      randomNumbers.push(selectedNumber);
    }

    randomNumbers.sort((a, b) => a - b);
    setRandomNumbers(randomNumbers);
  };

  const checkWordMatch = async () => {
    const selectedWords = randomNumbers.map((number) => {
      return mnemonicArray[number - 1];
    });
    const isMatched = inputWords.every(
      (word, index) => word === selectedWords[index]
    );

    if (isMatched) {
      setInputMaskBg(true);
      setIsTwoKeyLoading(true);

      handlerSetPublicKey();
    } else {
      checkInputPass();
    }
  };

  const handleInputChangeRandomWords = (index: number, value: string) => {
    const sanitizedValue = value.trim().toLowerCase();

    const isValid = /^[a-z]+$/.test(sanitizedValue);

    if (isValid) {
      setError(null);
      const updatedInputWords = [...inputWords];
      updatedInputWords[index] = sanitizedValue;
      setInputWords(updatedInputWords);
    } else if (sanitizedValue.length === 0) {
      setError(null);
      const updatedInputWords = [...inputWords];
      updatedInputWords[index] = sanitizedValue;
      setInputWords(updatedInputWords);
    } else {
      setError(intl.formatMessage({ id: "MNEMONIC_ONLY_LOWERCASE" }));
    }
  };

  const copyToClipBoard = async (address: string | undefined) => {
    try {
      await navigator.clipboard.writeText(address ?? "");
      if (tooltip) {
        tooltip.show();
        setTimeout(() => {
          tooltip.hide();
        }, 800);
      }
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handlerSetPublicKey = async () => {
    try {
      // Убедитесь, что библиотека libsodium-wrappers полностью загружена
      await ready;

      // Получение бинарного seed из мнемоники
      const seedBuffer = await mnemonicToSeed(mnemonic);
      // В Ed25519 приватный ключ имеет длину 32 байта. Используем первые 32 байта seed
      const seed = seedBuffer.slice(0, 32);

      // Генерация пары ключей Ed25519
      const keyPair = crypto_sign_seed_keypair(seed);

      // Перевод ключей в hex-формат
      const publicKeyHex = to_hex(keyPair.publicKey);

      setPublicKey(publicKeyHex, {
        onSuccess: () => {
          setIsTwoKeyLoading(false);
          nextPage(mnemonicPage + 1);
        },
      });

      return true;
    } catch (error) {
      console.error("Error in generating keys and signing the message:", error);
      // В случае ошибок возвращаем false
      return false;
    }
  };

  const checkInputPass = () => {
    setCheckScore(checkScore + 1);

    switch (checkScore) {
      case 1:
      case 2:
      case 3:
        setError({
          error: intl.formatMessage({ id: "MNEMONIC_INCORRECT_TRY_AGAIN" }),
        });
        setInputMaskBg(false);
        break;
      case 4:
        setError({
          error: intl.formatMessage({
            id: "MNEMONIC_INCORRECT_GET_NEW_PHRASE",
          }),
        });
        setInputMaskBg(false);
        break;
    }
  };

  const nextPage = (page: number) => {
    setMnemonicPage(page);
    setInProp(!inProp);
    setInputMaskBg(null);
    setError(null);
  };

  const handleClickNextPage = () => {
    setTimeout(() => {
      nextPage(mnemonicPage + 1);
    }, 500);
  };

  useEffect(() => {
    if (setPublicKeyError) {
      setIsTwoKeyLoading(false);
      setError({ error: intl.formatMessage({ id: "ERROR.GENERIC" }) });
    }
  }, [setPublicKeyError]);

  useEffect(() => {
    if (mnemonic && mnemRef.current) {
      const tooltip = new Tooltip(mnemRef.current, {
        trigger: "manual",
        title: intl.formatMessage({ id: "GLOBAL.COPIED" }),
        offset: [0, 5],
      });
      setTooltip(tooltip);
    }
  }, [mnemonic]);

  useEffect(() => {
    const mnemonic: string = generateMnemonic(128); // 128 бит - 12 слов
    setMnemonic(mnemonic);
    setMnemonicArray(mnemonic.split(" "));

    generateRandomNumbers();
  }, []);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={inProp}
      timeout={400}
      classNames="my-node"
    >
      <div ref={nodeRef}>
        {mnemonicPage === 1 ? (
          <>
            <div className="mb-10">
              {/* begin::Title */}
              <h1 className="text-dark fw-semibold mb-3 w-lg-400px display-6 mb-5">
                {intl.formatMessage({ id: "MNEMONIC_REMEMBER_SEED_PHRASE" })}
              </h1>
              {/* end::Title */}
              <p className="text-gray-500 pt-1 fw-semibold fs-6">
                {intl.formatMessage({ id: "MNEMONIC_REQUIRED_FOR_RECOVERY" })}
              </p>
              {/* end::Title */}
            </div>

            <div className="bg-light-warning rounded border-warning border border-dashed mb-10 mb-md-15 p-0 p-md-3">
              <div
                className="mx-5 d-flex align-items-center justify-content-between collapsible collapsed py-3 toggle mb-0"
                data-bs-toggle="collapse"
                data-bs-target={`#kt_job_1_1`}
              >
                <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary ">
                  <i className="ki-duotone ki-information fs-3x text-warning  me-4">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                  </i>
                </div>
                <h4
                  className="text-gray-700 fw-bold cursor-pointer mb-0 fs-md-3 fs-4"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {intl.formatMessage({ id: "MNEMONIC_SAFETY_RULES" })}
                </h4>

                <i className="ki-duotone ki-up toggle-on text-primary fs-1">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <i className="ki-duotone ki-down toggle-off fs-1">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                </i>
              </div>
              <div id={`kt_job_1_1`} className="collapse fs-6">
                <div className="mb-4 text-gray-600 fw-semibold fs-6 px-10">
                  <span className="d-block fw-bold fs-6">
                    {intl.formatMessage({ id: "MNEMONIC_SAFETY_RULE_1" })}
                  </span>
                  <span className="d-block fw-bold fs-6">
                    {intl.formatMessage({ id: "MNEMONIC_SAFETY_RULE_2" })}
                  </span>
                  <span className="d-block fw-bold fs-6">
                    {intl.formatMessage({ id: "MNEMONIC_SAFETY_RULE_3" })}
                  </span>
                  <span className="d-block fw-bold fs-6">
                    {intl.formatMessage({ id: "MNEMONIC_SAFETY_RULE_4" })}
                  </span>
                </div>
              </div>
            </div>

            <div className="mw-525px mx-auto d-flex flex-center border border-primary rounded p-4 mb-12 mb-md-15">
              <div className="d-flex flex-wrap fs-6">
                {mnemonicArray &&
                  mnemonicArray.map((word, idx) => {
                    return (
                      <span
                        key={`${word}-${idx}`}
                        className="border rounded p-1 m-2 bg-light"
                      >
                        <span className="text-gray-500 me-2">{idx + 1}</span>
                        {word}
                      </span>
                    );
                  })}
              </div>
              <button
                type="button"
                ref={mnemRef}
                className="ms-2 p-0 btn btn-light btn-active-white"
                style={{ background: "none" }}
                onClick={() => copyToClipBoard(mnemonic)}
              >
                <KTSVG
                  path="/media/icons/duotune/general/gen054.svg"
                  className="svg-icon-muted svg-icon-2hx m-0"
                />
              </button>
            </div>

            <button
              type="button"
              className="d-block w-100 w-md-50 m-auto btn btn-primary px-14 py-3"
              onClick={handleClickNextPage}
            >
              {intl.formatMessage({ id: "MNEMONIC_CONTINUE" })}
            </button>
          </>
        ) : mnemonicPage === 2 ? (
          <>
            <div className="mb-10">
              {/* begin::Title */}
              <h1 className="text-dark fw-semibold mb-3 w-lg-400px display-6 mb-5">
                {intl.formatMessage(
                  { id: "MNEMONIC_WRITE_ELEMENT" },
                  { numbers: randomNumbers.join(", ") }
                )}
              </h1>
              {/* end::Title */}
              <p className="text-gray-500 pt-1 fw-semibold fs-6">
                {intl.formatMessage({ id: "MNEMONIC_EXAMPLE" })}
              </p>
              {/* end::Title */}
            </div>

            <div className="mb-10">
              {inputWords.map((word, index) => (
                <input
                  key={index}
                  type="text"
                  value={word}
                  className={`form-control form-control-md form-control-solid mb-3 border border-${
                    inputMaskBg === null
                      ? ""
                      : inputMaskBg
                      ? "success"
                      : "danger"
                  }`}
                  onChange={(e) =>
                    handleInputChangeRandomWords(index, e.target.value)
                  }
                  placeholder={intl.formatMessage(
                    { id: "MNEMONIC_WRITE_ELEMENT" },
                    { numbers: randomNumbers[index] }
                  )}
                />
              ))}
            </div>

            {checkScore < 4 ? (
              <button
                type="button"
                className="d-block m-auto btn btn-primary px-14 py-3 mt-10 mt-md-15 w-100"
                onClick={checkWordMatch}
              >
                {isTwoKeyLoading ? (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    <span className="spinner-border spinner-border-sm align-middle me-2 mb-1"></span>
                    {intl.formatMessage({ id: "GLOBAL.PLEASE_WAIT" })}
                  </span>
                ) : (
                  intl.formatMessage({ id: "MNEMONIC_CHECK" })
                )}
              </button>
            ) : (
              <button
                type="button"
                className="d-block me-auto btn btn-primary px-14 py-3 mb-20"
                onClick={() => {
                  setTimeout(() => {
                    setInputWords(initialInputWords);
                    setCheckScore(initialCheckScore);
                    nextPage(mnemonicPage - 1);
                  }, 500);
                }}
              >
                {intl.formatMessage({ id: "MNEMONIC_BACK" })}
              </button>
            )}
          </>
        ) : mnemonicPage === 3 ? (
          <>
            <div className="mb-10">
              {/* begin::Title */}
              <h1 className="text-dark fw-semibold mb-3 w-lg-400px display-6 mb-5">
                {intl.formatMessage({ id: "MNEMONIC_CONGRATULATIONS" })}
              </h1>
              {/* end::Title */}
              <p className="text-gray-500 pt-1 fw-semibold fs-6">
                {intl.formatMessage({ id: "MNEMONIC_SEED_SUCCESS" })}
              </p>
              {/* end::Title */}
            </div>

            <button
              type="button"
              className="d-block me-auto btn btn-primary px-14 py-3"
              onClick={() => {
                navigate("/");
                window.location.reload();
              }}
            >
              {intl.formatMessage({ id: "MNEMONIC_CONTINUE" })}
            </button>
            {/* <div className="mb-10">
              <TelegramLoginButton />
            </div> */}
          </>
        ) : (
          <>
            <div className="mb-10">
              {/* begin::Title */}
              <h1 className="text-dark fw-semibold mb-3 w-lg-400px display-6 mb-5">
                {intl.formatMessage({ id: "MNEMONIC_ERROR" })}
              </h1>
              {/* end::Title */}
              <p className="text-gray-500 pt-1 fw-semibold fs-6">
                {intl.formatMessage({ id: "MNEMONIC_SEED_NOT_SET" })}
              </p>
              {/* end::Title */}
            </div>

            <button
              type="button"
              className="d-block me-auto btn btn-primary px-14 py-3"
              onClick={() => {
                navigate("/deals/create");
                window.location.reload();
              }}
            >
              {intl.formatMessage({ id: "MNEMONIC_GO_HOME" })}
            </button>
          </>
        )}
        {error && (
          <div className="mt-10 mw-375px mx-auto text-center">
            {ShowError(error)}
          </div>
        )}
      </div>
    </CSSTransition>
  );
};
