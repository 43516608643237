import { useIntl } from "react-intl";

export const DevToolsWarning = () => {
  const intl = useIntl();

  return (
    <div className="card w-100 h-100 d-flex flex-center">
      <h1>
        {intl.formatMessage({
          id: "ERROR_PAGE.DEVTOOLS_WARNING",
        })}
      </h1>
    </div>
  );
};
