import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth } from "../../auth";
import { useIsHaveMnemonic } from "../../auth/core/_requests";

interface UserContextType {
  isScrollToQR: boolean;
  setIsScrollToQR: (state: boolean) => void;
  isHaveMnemonic: boolean;
}

interface UserProviderProps {
  children: ReactNode;
}

const UserContext = createContext<UserContextType>({} as UserContextType);

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const { currentUser } = useAuth();

  const [isScrollToQR, setIsScrollToQR] = useState(false);

  const [isHaveMnemonic, setIsHaveMnemonic] = useState(true);

  const { mutate: getMnemonicStatus, data: mnemonicStatus } =
    useIsHaveMnemonic();

  useEffect(() => {
    if (currentUser) {
      getMnemonicStatus();
    }
  }, [currentUser]);

  useEffect(() => {
    if (mnemonicStatus) {
      setIsHaveMnemonic(mnemonicStatus.phrase);
    }
  }, [mnemonicStatus]);

  return (
    <UserContext.Provider
      value={{
        isScrollToQR,
        setIsScrollToQR,

        isHaveMnemonic,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserData = () => useContext(UserContext);
