import { FC } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { checkIsActive } from "../../../../../_metronic/helpers";

type Props = {
  to: string;
  title: React.ReactNode;
};

export const NavLink: FC<Props> = ({ to, title }) => {
  const { pathname } = useLocation();

  return (
    <Link
      className={clsx("text-hover-freewin", {
        "text-gray-900": !checkIsActive(pathname, to),
        "text-freewin": checkIsActive(pathname, to),
      })}
      to={to}
    >
      {title}
    </Link>
  );
};
